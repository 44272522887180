/**
 * @category  Scandiweb
 * @package   ScandiPWA/AlternativeInfiniteLoading
 * @author    Ramona Cunska <info@scandiweb.com> on (ScandiPWA/CatalogLoadMore package)
 * @author    Erick Lima <info@scandiweb.com>
 */

export class ProductListContainerPlugin {
    /**
     * Extended to add additional functions to container.
     */
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        loadNextPageProducts: this.loadNextPageProducts.bind(instance),
        getLastLoadedPage: this.getLastLoadedPage.bind(instance)
    });

    /**
     * Extended to pass additional props to component.
     */
    containerProps = (args, callback, instance) => {
        const { isPageLoading } = instance.props;
        const { isNextPagePreloaded } = instance.state;

        return {
            ...callback.apply(instance, args),
            isPageLoading,
            isNextPagePreloaded,
            shouldRenderLoaderOverlay: this.shouldRenderLoaderOverlay.apply(instance),
            nextPageCount: this.getNextPageCount.apply(instance)
        };
    };

    /**
     * Adding new state to container.
     * Extending state directly threw an error so we are doing it through constructor.
     */
    constructExtended = (args, callback, instance) => {
        callback.apply(instance, args);

        // eslint-disable-next-line no-param-reassign
        instance.state = {
            ...instance.state,
            isNextPagePreloaded: false
        };
    };

    componentDidMount = (args, callback, instance) => {
        const {
            pages, isPreventRequest,
            requestProductsWithLabels,
            requestProductsWithLabelsSetting,
        } = instance.props;
        const { pagesCount } = instance.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            instance.setState({ pagesCount: pagesLength });
        }
        // Is true when category is changed. This check prevents making new requests when navigating back to PLP from PDP
        if (!isPreventRequest) {
            instance.requestPage(instance._getPageFromUrl());
            const productId = Object.values(pages).flatMap((items) => items.map(({ id }) => id));
            const mode = 'CATEGORY';
            const options = {
                        args: {
                            productId,
                            mode
                        }
                    };

            if (productId.length !== 0) {
                requestProductsWithLabelsSetting();
                requestProductsWithLabels(options);
            }
        }

        instance.setState({ isScriptgetGtmCode: false });

        callback(...args);
    }

    /**
     * Extended to reset preloaded state if filters are changed.
     * Extended to remove 'currentPage !== prevPage' expression to prevent unnecessary 'requestPage' call.
     */
    componentDidUpdate = (args, callback, instance) => {
        const [prevProps, prevState] = args;
        const {
            sort,
            search,
            filter,
            pages,
            isInfiniteLoaderEnabled,
            isPlp,
            requestProductsWithLabelsSetting,
            requestProductsWithLabels,
        } = instance.props;
        const {
            sort: prevSort,
            search: prevSearch,
            filter: prevFilter,
            location: prevLocation,
        } = prevProps;

        if (!isInfiniteLoaderEnabled) {
            return callback.apply(instance, args);
        }

        const { pagesCount , isScriptgetGtmCode, isPagesArray, isScriptStopArray} = instance.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            instance.setState({ pagesCount: pagesLength });
        }

        if (
            search !== prevSearch
            || JSON.stringify(sort) !== JSON.stringify(prevSort)
            || JSON.stringify(filter) !== JSON.stringify(prevFilter)
        ) {
            instance.setState({ isNextPagePreloaded: false });
            instance.requestPage(instance._getPageFromUrl());
        }

        const prevPage = instance._getPageFromUrl(prevLocation);
        const currentPage = instance._getPageFromUrl();
        const isSortUpdated = JSON.stringify(sort) !== JSON.stringify(prevSort);
        const isProductListUpdated = JSON.stringify(filter) !== JSON.stringify(prevFilter) || currentPage !== prevPage;
        // prevents requestPage() fired twice on Mobile PLP with enabled infinite scroll
        if (pagesCount !== pagesLength && instance._getIsInfiniteLoaderEnabled() && isPlp && !(isProductListUpdated || isSortUpdated)) {
            const productId = Object.values(pages).flatMap((items) => items.map(({ id }) => id));
            const mode = 'CATEGORY';
            const options = {
                        args: {
                            productId,
                            mode
                        }
                    };

            if (productId.length !== 0) {
                requestProductsWithLabelsSetting();
                requestProductsWithLabels(options);
            }
            instance.setState({ isScriptgetGtmCode: true });
        }
        const pagesArray = Object.values(pages);
        const lengthArray = pagesArray?.length > 0;
        const ispageArraylength = isPagesArray?.length > 0;
        const isPagesArraylength = ispageArraylength && lengthArray && JSON.stringify(pagesArray[0]) !== JSON.stringify(isPagesArray[0]);
        
        if(!isPagesArraylength && !isScriptgetGtmCode && !isScriptStopArray && lengthArray && instance._getIsInfiniteLoaderEnabled()){
            const pagesArray = Object.values(pages);
            instance.setState({ isPagesArray: pagesArray, isScriptStopArray: true });
        }
        
        if(isPagesArraylength && !isScriptgetGtmCode && instance._getIsInfiniteLoaderEnabled()) {
            const productId = Object.values(pages).flatMap((items) => items.map(({ id }) => id));
            const mode = 'CATEGORY';
            const options = {
                        args: {
                            productId,
                            mode
                        }
                    };

            if (productId.length !== 0) {
                requestProductsWithLabelsSetting();
                requestProductsWithLabels(options);
            }
            instance.setState({ isScriptgetGtmCode: true });
        }
    };

    /**
     * Extended to set preloaded state when next product page requested.
     * Extended to remove page scroll-up.
     */
    requestPage = (args, callback, instance) => {
        const [currentPage, isNext = false] = args;
        const {
            sort,
            search,
            filter,
            pageSize,
            requestProductList,
            requestProductListInfo,
            noAttributes,
            noVariants,
            isWidget,
            isInfiniteLoaderEnabled,
        } = instance.props;

        if (!isInfiniteLoaderEnabled) {
            return callback.apply(instance, args);
        }

        /**
         * In case the wrong category was passed down to the product list,
         * prevent it from being requested.
         */
        if (filter.categoryIds === -1) {
            return;
        }

        /**
         * Do not request page if there are no filters
         */
        if (!search && !instance.isEmptyFilter()) {
            return;
        }

        // TODO: product list requests filters alongside the page
        // TODO: sometimes product list is requested more then once
        // TODO: the product list should not request itself, when coming from PDP

        const options = {
            isNext,
            noAttributes,
            noVariants,
            args: {
                sort,
                filter,
                search,
                pageSize,
                currentPage
            }
        };

        const infoOptions = {
            args: {
                filter,
                search
            }
        };

        requestProductList(options);

        if (!isWidget) {
            requestProductListInfo(infoOptions);
        }
        if (isNext) {
            instance.setState({ isNextPagePreloaded: true });
        }
    };

    /**
     * Extended to enable infinite product loading when "alternative infinite loader" functionality is enabled.
     */
    _getIsInfiniteLoaderEnabled = (args, callback, instance) => {
        const { isInfiniteLoaderEnabled } = instance.props;

        return isInfiniteLoaderEnabled;
    };

    /**
     * Check if the loader overlay should be rendered
     * @returns {Boolean}
     */
    shouldRenderLoaderOverlay() {
        const { isLoading, isPageLoading } = this.props;

        const { isNextPagePreloaded } = this.state;
        const { maxPage, totalPages } = this._getPagesBounds();
        const lastLoadedPage = isNextPagePreloaded && !isPageLoading ? maxPage - 1 : maxPage;

        // return !isLoading && lastLoadedPage < totalPages;
        return (isPageLoading || isLoading) && lastLoadedPage < totalPages;
    }

    /**
     * Get the number of items in next page.
     * @returns {Number}
     */
    getNextPageCount() {
        const { isPageLoading, pageSize, totalItems } = this.props;
        const { isNextPagePreloaded } = this.state;
        const { maxPage } = this._getPagesBounds();

        const lastLoadedPage = isNextPagePreloaded && !isPageLoading ? maxPage - 1 : maxPage;
        const loadedItemsCount = (lastLoadedPage * pageSize) > totalItems ? totalItems : lastLoadedPage * pageSize;
        const remainingItemsCount = totalItems - loadedItemsCount;

        return remainingItemsCount > pageSize ? pageSize : remainingItemsCount;
    }

    getLastLoadedPage() {
        const { isPageLoading } = this.props;
        const { isNextPagePreloaded } = this.state;
        const { maxPage } = this._getPagesBounds();

        return isNextPagePreloaded && !isPageLoading
            ? maxPage - 1
            : maxPage;
    }

    /**
     * Actions to call on "load more" button click.
     */
    loadNextPageProducts() {
        this.setState({ isNextPagePreloaded: false });
    }
}

const {
    containerFunctions,
    containerProps,
    constructExtended,
    componentDidMount,
    componentDidUpdate,
    requestPage,
    _getIsInfiniteLoaderEnabled
} = new ProductListContainerPlugin();

export default {
    'Component/ProductList/Container': {
        'member-function': {
            containerFunctions,
            containerProps,
            __construct: constructExtended,
            componentDidMount,
            componentDidUpdate,
            requestPage,
            _getIsInfiniteLoaderEnabled
        }
    }
};
